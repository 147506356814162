<div [formGroup]="parentFormGroup">
  <select
    class="form-control form-control-sm {{selectorClass}}"
    name="{{selectorControlName}}"
    id="{{selectorControlId}}"
    (change)="onHubChange($event)"
    [disabled]="true"
    [formControlName]="parentFormControlName"
  >
    <option
      *ngFor="let hub of hubs"
      [value]="hub.id"
    >
      {{showFullName ? hub.full_name : hub.name}}
    </option>
  </select>
</div>
