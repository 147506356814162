import {ApiService} from '../shared/services/api.service';
import {Injectable} from '@angular/core';

@Injectable()
export class OrdersApiService extends ApiService {
  /**
   * Список заказов
   * @param params
   */
  public getOrders(params) {
    return this.get('/client/orders?' + this.getQueryStringFromArray(params));
  }

  /**
   * Заказ по uid
   * @param uid
   */
  public getOrderByUid(uid: string) {
    return this.get('/client/orders/uid/' + uid);
  }

  /**
   * Подпись получателя заказа
   * @param uid
   */
  public getOrderRecipientSignature(uid: string) {
    return this.get('/client/orders/recipient-signature/' + uid);
  }

  /**
   * Статусы заказов
   */
  public getOrderStatuses() {
    return this.get('/client/orders/statuses');
  }

  /**
   * Отменить заказ
   * @param uid
   */
  public cancelOrder(uid: string) {
    return this.post('/client/orders/cancel/' + uid, {});
  }

  /**
   * Отменить заказы
   * @param uids
   */
  public cancelOrders(uids: string[]) {
    return this.post('/client/orders/cancelOrders', {uids: uids});
  }

  /**
   * Подтвердить заказы
   * @param uids
   */
  public confirmOrders(uids: string[]) {
    return this.post('/client/orders/confirmOrders', {uids: uids});
  }

  /**
   * Создать заказ
   * @param params
   */
  public createOrder(params) {
    return this.post('/client/orders/create', params);
  }

  /**
   * Сохранить заказ
   * @param uid
   * @param params
   */
  public updateOrder(uid: string, params) {
    return this.post('/client/orders/update/' + uid, params);
  }

  /**
   * Делает копию заказа
   * @param innerN
   */
  public copyOrder(innerN: string) {
    return this.post('/client/orders/copyOrder', {inner_n: innerN});
  }


  /**
   * Расчёт стоимости доставки
   * @param params
   */
  public calculateRequest(params) {
    return this.post('/client/orders/calculate-request', params);
  }

  /**
   * Импорт заказов из Excel
   * @param form
   */
  public importFromExcel(form) {
    return this.post('/client/orders/import-excel', form);
  }

  /**
   * Список заказов с местами
   * @param payload
   */
  public getOrdersWithPlaces(payload) {
    return this.post('/client/orders/getOrdersWithPlaces', payload);
  }


  /**
   * Список заказов с товарамм
   * @param params
   */
  public getOrdersWithGoods(params) {
    return this.post('/client/orders/getOrdersWithGoods', params);
  }

  /**
   * Удаление места
   * @param payload
   */
  public deleteOrderPlace(payload) {
    return this.post('/client/orders/deleteOrderPlace', payload);
  }

  /**
   * Получить данные для этикеток
   * @param params
   */
  public getLabelsData(params) {
    return this.post('/client/orders/getLabelsData', params);
  }

  /**
   * Получение истории заказа по uid
   * @param uid
   */
  getOrderHistory(uid: string) {
    return this.get('/client/orders/history/' + uid);
  }

  /**
   * Получение информации о заказе
   * @param uid
   */
  getOrderInfo(uid: string) {
    return this.get('/client/orders/info/' + uid);
  }

  /**
   * Смена признака видимости заказа в getstatusv3
   */
  setVisibilityForApi(uid: string, isVisible: number | string | boolean) {
    return this.post('/client/orders/setVisibilityForApi/' + uid + '/' + (isVisible ? '1' : '0'), {});
  }
}
