<div *ngIf="data.type === 'alert'" style="z-index: 100">
  <div class="responses">
    <ng-container *ngIf="!data.isHtml">
      <p>{{data.message}}</p>
    </ng-container>

    <ng-container *ngIf="data.isHtml">
      <div class="container" [innerHTML]="data.message"></div>
    </ng-container>
  </div>
  <div class="singleButton">
    <button class="icon_button i-check button" [matDialogClose]="true" (click)="onOk()">OK</button>
  </div>
</div>

<div *ngIf="data.type === 'confirm'">
  <div class="responses">
    <p>{{data.message}}</p>
  </div>
  <div class="buttons">

    <button class="icon_button i-cancel button" (click)="onCancel()">Отмена</button>
    <button class="icon_button i-check button" [matDialogClose]="true" (click)="onOk()">OK</button>
  </div>
</div>

<div *ngIf="data.type === 'confirmYesNo'">
  <div class="responses">
    <p>{{data.message}}</p>
  </div>
  <div class="row">
    <div class="col text-center">
      <button class="icon_button i-cancel button" (click)="onCancel()">{{data.no}}</button>
    </div>

    <div class="col text-center">
      <button class="icon_button i-check button" [matDialogClose]="true" (click)="onOk()">{{data.yes}}</button>
    </div>
  </div>
</div>


<div *ngIf="data.type === 'edit'"  [formGroup]="editForm" >
  <div class="responses">
    <h4>{{data.message}}</h4>
    <input placeholder="{{data.placeholder}}"
           formControlName="editControl"
           class="input">
  </div>
  <div  style="padding-top:10px;text-align:center">
    <button class="button btn-light" (click)="onCancel()">Отмена</button>
    <button class="button" (click)="onFinishEdit()">Сохранить</button>

  </div>
</div>

<div *ngIf="data.type === 'edit_textarea'"  [formGroup]="editForm" >
  <div class="responses">
    <h4>{{data.message}}</h4>
    <textarea
      formControlName="editControl"
      class="form-control textarea">{{data.placeholder}}</textarea>
  </div>
  <div class="mt-2">
    <button class="button btn-light" (click)="onCancel()">Отмена</button>
    <button class="button greens float-right" (click)="onFinishEdit()">Сохранить</button>
  </div>
</div>

<div *ngIf="data.type == 'image'">

  <img *ngIf="data.image" style="display:block; max-width:95%;max-height:75%" id="base64image" src="data:image/png;base64,{{data.image}}">
  <img *ngIf="data.url" src="{{data.url}}" style="display:block; max-width:95%;max-height:75%" >
  <div  style="padding-top:10px;text-align:center">
    <button class="icon_button i-check button" (click)="onOk()">Закрыть</button>
  </div>
</div>
