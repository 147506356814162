import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})

export class DialogComponent {

    title = 'Angular-Interceptor';
    editForm: FormGroup;
    editControl: FormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        public dialog: MatDialog
    ) {
      if (this.data.type === 'edit' || this.data.type === 'edit_textarea') {
        this.editForm = new FormGroup({
          editControl: new FormControl(this.data.value)
        });
      }
    }

    onCancel() {
        this.data.event.emit(false);
        this.dialog.closeAll();
    }

    onOk() {
        this.data.event.emit(true);
        //   this.dialog.closeAll();
    }

    onFinishEdit() {
      this.data.event.emit(this.editForm.value.editControl);
      this.dialog.closeAll();
    }
}
